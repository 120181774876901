
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  onIonViewWillEnter
} from "@ionic/vue";
import {
  sparkles,
  receipt,
  checkboxOutline,
  searchOutline
} from "ionicons/icons";
import { useStorage } from "@/composables/useStorage";
import { ref } from "vue";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet
  },
  setup() {
    const isAdmin = ref(false);
    onIonViewWillEnter(async () => {
      const { getUser } = useStorage();
      isAdmin.value = (await getUser()).isAdmin;
    });
    return {
      isAdmin,
      sparkles,
      receipt,
      checkboxOutline,
      searchOutline
    };
  }
};
