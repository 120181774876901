import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab1",
                href: "/tabs/expenses"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.receipt }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("My Expenses")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "tab2",
                href: "/tabs/new"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.sparkles }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("New")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              ($setup.isAdmin)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "tab3",
                    href: "/tabs/review"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.checkboxOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Review")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($setup.isAdmin)
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 1,
                    tab: "tab4",
                    href: "/tabs/search"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: $setup.searchOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Search")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}